import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthProvider";
import "../styles.css";
import { APIClient } from "../../../utils/services";
import { useEffect, useState } from "react";
import ModalCancelSubscription from "./ModalCancelSubscription";
import { Spinner } from "react-bootstrap";
import Pricing from "../Pricing";
import { capitalize } from "../../../utils";
import ModalConfirmationCancel from "./ModalConfirmationCancel";
import axios from "axios";

interface AccountInfo {
  medias_limit: number;
  medias_used: number;
  audios_limit: number;
  audios_used: number;
  account_type: string;
}

const BASE_URL = process.env.REACT_APP_MUZE_API_HOST || "http://localhost:8002";

export default function Billing() {
  const navigate = useNavigate();
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const className = `d-flex flex-column justify-content-start flex-grow-1 text-center border rounded ${
    isMobileDevice() ? "overflow-auto" : ""
  }`;
  const style = {
    height: isMobileDevice() ? "600px" : "100%",
    maxWidth: "100%",
  };

  const service = new APIClient();
  const [showCancelPlan, setShowCancelPlan] = useState(false);
  const [showErroreCancelPlan, setShowErrorCancelPlan] = useState(false);
  const [showConfirmCancelPlan, setShowConfirmCancelPlan] = useState(false);

  const [showPurchase, setShowPurchase] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);

  const { currentUser } = useAuth();
  const ownerId = `${currentUser?.email}`;
  const encodedEmail = encodeURIComponent(ownerId);
  const [error, setError] = useState("");
  const [customerId, setCustomerId] = useState("");

  const handleRetrieveCustomerId = async () => {
    console.log(ownerId);
    console.log(encodedEmail);
    try {
      const response = await axios.post(`${BASE_URL}/retrieve-customer-id`, {
        email: ownerId,
      });

      console.log("response", response);

      return response?.data?.customer_id;
    } catch (err: any) {
      setError(
        err.response
          ? err.response.data.detail
          : "Failed to retrieve customer ID"
      );
      return false;
    }
  };

  const handleManageBilling = async () => {
    try {
      const customerId = await handleRetrieveCustomerId();
      if (customerId) {
        const response = await axios.post(
          `${BASE_URL}/create-customer-portal-session`,
          {
            customer_id: customerId,
          }
        );

        const portalUrl = response.data.url;
        console.log(portalUrl);
        window.location.href = portalUrl;
      }
    } catch (err: any) {
      setError(err.response ? err.response.data.detail : "An error occurred");
    }
  };

  const loginUrl = `https://billing.stripe.com/p/login/9AQ03f3p6cAMcuY288?prefilled_email=${encodedEmail}`;

  useEffect(() => {
    service
      .getPlanByEmail(ownerId)
      .then((res: any) => {
        setLoading(false);
        setAccountInfo(res.Item);
      })
      .catch((error: any) => {
        console.error("Error fetching plan subscription:", error);
      });
  }, [ownerId]);

  const logOut = () => {
    navigate("/");
  };

  const backDashboard = () => {
    navigate("/app/dashboard");
  };

  function capitalizeFirstLetter(str: any) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const mediaRemaining = accountInfo
    ? accountInfo.medias_limit - accountInfo.medias_used
    : 0;
  const audioRemaining = accountInfo
    ? accountInfo.audios_limit - accountInfo.audios_used
    : 0;
  const accountType = accountInfo?.account_type;
  const formattedAccountType = capitalizeFirstLetter(accountType);

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" } as const;
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <div {...{ className, style }}>
      {showPurchase && <Pricing />}
      {showConfirmCancelPlan && (
        <ModalConfirmationCancel
          show={showConfirmCancelPlan}
          onHide={() => setShowConfirmCancelPlan(false)}
          name="Cancel Subscription"
          description="We're sad to see you go! Are you sure you want to cancel your subscription?"
          notConfirm={backDashboard}
        />
      )}
      {showErroreCancelPlan && (
        <ModalCancelSubscription
          show={showErroreCancelPlan}
          onHide={() => setShowErrorCancelPlan(false)}
          name="Fail"
          description="Sorry, we are unable to cancel your subscription at this moment. Please contact us for further assistance!"
        />
      )}
      {showCancelPlan && (
        <ModalCancelSubscription
          show={showCancelPlan}
          onHide={() => setShowCancelPlan(false)}
          name="Subscription cancellation scheduled"
          description="Your subscription will be canceled in the next billing period. Take advantage of this period to enjoy our application while your plan is still active!"
          backToDashboard={backDashboard}
        />
      )}
      {showCancelPlan && (
        <ModalCancelSubscription
          show={showCancelPlan}
          onHide={() => setShowCancelPlan(false)}
          name="Subscription cancellation scheduled"
          description="Your subscription will be canceled in the next billing period. Take advantage of this period to enjoy our application while your plan is still active!"
          backToDashboard={backDashboard}
        />
      )}
      {isMobileDevice()
        ? !showPurchase && (
            <>
              <h3
                className="border-bottom p-3 text-start"
                style={{ fontWeight: "bolder" }}
              >
                {title}
              </h3>
              <div className="text-start p-3">
                <h5
                  className="pb-3"
                  style={{ fontWeight: "bold", fontSize: "medium" }}
                >
                  {loading ? (
                    <Spinner />
                  ) : (
                    <div>{`Your Current Plan: ${formattedAccountType}`}</div>
                  )}
                </h5>
                <div className="d-flex">
                  <i
                    className="bi bi-film"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p>{`You have ${mediaRemaining} media credits remaining`}</p>
                </div>
                <div className="d-flex">
                  <i
                    className="bi bi-music-note-beamed"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p>{`You have ${audioRemaining} audio credits remaining`}</p>
                </div>
                <div className="d-flex">
                  {/* <i
                    className="bi bi-calendar-check-fill"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p
                    style={{ fontWeight: "bold" }}
                  >{`Your credits will be reset on ${
                    nextBilledAt ? formatDate(nextBilledAt) : "..."
                  }`}</p> */}
                </div>
                <div style={{ marginTop: "20px" }}>
                  <p
                    className="btn btn-manage-account"
                    onClick={() => {
                      window.open(loginUrl, "_blank");
                    }}

                    // onClick={handleManageBilling}
                  >
                    Manage Plan and Credits
                  </p>
                </div>
              </div>
            </>
          )
        : !showPurchase && (
            <>
              <h1
                className="border-bottom p-3 text-start"
                style={{ fontWeight: "bolder" }}
              >
                {title}
              </h1>
              <div className="text-start p-3">
                <h4 className="pb-3" style={{ fontWeight: "bold" }}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <div>{`Your Current Plan: ${formattedAccountType}`}</div>
                  )}
                </h4>
                <div className="d-flex">
                  <i
                    className="bi bi-film"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p>{`You have ${mediaRemaining} media credits remaining`}</p>
                </div>
                <div className="d-flex">
                  <i
                    className="bi bi-music-note-beamed"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i>
                  <p>{`You have ${audioRemaining} audio credits remaining`}</p>
                </div>
                <div className="d-flex">
                  {/* <i
                    className="bi bi-calendar-check-fill"
                    style={{
                      marginRight: "10px",
                      color: "#FF30C4",
                      fontSize: "18px",
                    }}
                  ></i> */}
                  {/* <p
                    style={{ fontWeight: "bold" }}
                  >{`Your credits will be reset on ${
                    nextBilledAt ? formatDate(nextBilledAt) : "..."
                  }`}</p> */}
                </div>
                <div style={{ marginTop: "20px" }}>
                  <p
                    className="btn btn-manage-account"
                    onClick={() => {
                      window.open(loginUrl, "_blank");
                    }}
                    // onClick={handleManageBilling}
                  >
                    Manage Plan and Credits
                  </p>
                </div>
              </div>
            </>
          )}
    </div>
  );
}

const title = "Billing";

import { NextPrev } from "../AudioPage";
import {
  useAppSelector,
  useAudioTrim,
  useAutomaticTimeStore,
  useNegativePrompts,
  useStartingFrame,
  useSubjectMatter,
} from "../../store/hooks";
import GenerateVideoBtn from "../GenerateVideo/GenerateVideoBtn";
import AvatarSelection from "../AvatarSelect";
import LyricsBox, {
  formatMinutes,
  formatSeconds,
} from "../GenerateVideo/Prompts/LyricsBox";
import Separator from "../Login/Separator";
import "./styles.css";
import { useEffect, useState } from "react";
import { validTime } from "../../utils/utils";
import toast from "react-hot-toast";
import { APIClient } from "../../utils/services";
import { useAuth } from "../../AuthProvider";
import ModalPurchase from "../GenerateVideo/ModalPurchase";
import ModalRemainCredits from "../GenerateVideo/ModalRemainCredits";
import { selectAudioAsset } from "../../store/audioAssetReducer";
import TrimPlayerPrompts from "./TrimPlayerPrompts";

const TITLE = "Prompts";
const TIMING_LABEL = "Timing";
const SUBTITLE1 = `Describe what you want to see in your video.`;
//const SUBTITLE1_IMAGE_TO_VIDEO = `Your first prompt should be a short description of what you see in the image you uploaded.`;
const SUBTITLE2 = `Each new prompt is like a new scene`;
const SUBTITLE2_MOBILE = `Each new prompt is like a new scene, so, write your prompt below`;
const DESCRIPTION = `If you want to use an avatar in your video select it from the dropdown menu and in the prompt use the word`;
const LABEL = "Write your prompt";
const TIP = `Use the word {avatar} to refer to it in your prompt. Example {avatar} playing guitar.`;
const ADD = `Add more scene`;
const imageBucketBaseURL = process.env.REACT_APP_IMAGES_BUCKET_URL;

export default function PromptsPage() {
  //const path = window.location.pathname;
  const [showModalPurchase, setShowModalPurchase] = useState(false);
  const [showModalRemain, setShowModalRemain] = useState(false);
  const [showAvatarSelection, setShowAvatarSelection] = useState(true);
  const [selectedImagePrompt, setSelectedImagePrompt] = useState("");
  const { selected } = useAppSelector(selectAudioAsset);
  const httpLink = selected?.http_link || "";

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="ps big-card rounded m-1 d-flex flex-column w-100 flex-grow-1 align-items-center justify-content-center">
          <div
            className="col-12 d-flex flex-column p-1"
            style={{ backgroundColor: "#141718" }}
          >
            <NextPrev hideNext />
            <header
              className="p-1 d-flex w-100 flex-column gap-2 text-center mb-1"
              style={{ width: window.innerWidth < 1600 ? "650px" : "800px" }}
            >
              <h1 className="fw-bold">{TITLE}</h1>
            </header>
            <AvatarSelection
              setShowAvatarSelection={setShowAvatarSelection}
              setSelectedImagePrompt={setSelectedImagePrompt}
            />
            {(showAvatarSelection || selectedImagePrompt) &&
              httpLink &&
              selected && <TrimPlayerPrompts url={httpLink} active />}
            <LyricsBox />
          </div>
          {/* MAIN */}
          <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center border-end h-100">
            <Prompts
              showModalPurchase={showModalPurchase}
              setShowModalPurchase={setShowModalPurchase}
              showModalRemain={showModalRemain}
              setShowModalRemain={setShowModalRemain}
            />
            <div
              className="d-flex justify-content-end align-items-end w-100"
              style={{ marginRight: "40px", padding: "20px" }}
            >
              <GenerateVideoBtn
                setShowModalPurchase={setShowModalPurchase}
                setShowModalRemain={setShowModalRemain}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="ps big-card rounded m-3 d-flex flex-grow-1 align-items-center justify-content-center">
          {/* MAIN */}
          <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center border-end h-100">
            <header
              className="p-3 d-flex flex-column gap-2 text-center mb-4"
              style={{ width: window.innerWidth < 1600 ? "650px" : "800px" }}
            >
              {(showAvatarSelection || selectedImagePrompt) &&
                httpLink &&
                selected && <TrimPlayerPrompts url={httpLink} active />}
              <h1 className="fw-bold">{TITLE}</h1>
              <div className="d-flex flex-column">
                <p className="text-muted p-0 m-0">{SUBTITLE1}</p>
                <p className="text-muted p-0 m-0">{SUBTITLE2}</p>
              </div>
            </header>
            <Prompts
              showModalPurchase={showModalPurchase}
              setShowModalPurchase={setShowModalPurchase}
              showModalRemain={showModalRemain}
              setShowModalRemain={setShowModalRemain}
            />
          </div>

          {/* SIDE */}
          <div className="d-flex flex-column p-3 h-100 gap-2">
            <AvatarSelection
              setShowAvatarSelection={setShowAvatarSelection}
              setSelectedImagePrompt={setSelectedImagePrompt}
            />
            <Separator height={"0.001em"} />
            <LyricsBox />
            <GenerateVideoBtn
              setShowModalPurchase={setShowModalPurchase}
              setShowModalRemain={setShowModalRemain}
            />
            <NextPrev hideNext fill />
          </div>
        </div>
      )}
    </>
  );
}

function Prompts({
  showModalPurchase,
  setShowModalPurchase,
  showModalRemain,
  setShowModalRemain,
}: {
  showModalPurchase: any;
  setShowModalPurchase: any;
  showModalRemain: any;
  setShowModalRemain: any;
}) {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const { subject, setSubjectMatter } = useSubjectMatter();
  const { initialImageKey } = useStartingFrame();
  const { automaticTime, setAutomaticTime } = useAutomaticTimeStore();
  const [isAccountType, setIsAccountType] = useState(null);
  const [timing, setTiming] = useState("automatic");
  const { enableNegativePrompts, setNegativePrompts } = useNegativePrompts();
  const { start, end } = useAudioTrim();

  const delta = end - start;
  const service = new APIClient();

  const initialImage = `${imageBucketBaseURL}/${ownerId}/${decodeURIComponent(
    initialImageKey
  )}`;

  // const subjectMatterOptions = [
  //   [
  //     {
  //       time: "00:00",
  //       label: "Aerial shot of a night market in Brazil",
  //       error: false,
  //       negativePrompt: "",
  //     },
  //   ],
  //   [
  //     {
  //       time: "00:00",
  //       label: "An Indian sadhu meditating on the banks of the Ganges river",
  //       error: false,
  //       negativePrompt: "",
  //     },
  //   ],
  //   [
  //     {
  //       time: "00:00",
  //       label:
  //         "Graffiti on a wall, new york street, latino, sunny, fisheye lens",
  //       error: false,
  //       negativePrompt: "",
  //     },
  //   ],
  //   [
  //     {
  //       time: "00:00",
  //       label:
  //         "Brazilian parade, carnival in rio, anime, colorful, crowd parade",
  //       error: false,
  //       negativePrompt: "",
  //     },
  //   ],
  //   [
  //     {
  //       time: "00:00",
  //       label: "Robot snake crawling",
  //       error: false,
  //       negativePrompt: "",
  //     },
  //   ],
  //   [
  //     {
  //       time: "00:00",
  //       label: "Lonely robot sitting in lotus position in space meditating",
  //       error: false,
  //       negativePrompt: "",
  //     },
  //   ],
  //   [
  //     {
  //       time: "00:00",
  //       label: "Lonely Amazonian shaman standing in the forest",
  //       error: false,
  //       negativePrompt: "",
  //     },
  //   ],
  // ];

  // const getRandomSubjectMatter = () => {
  //   const randomIndex = Math.floor(Math.random() * subjectMatterOptions.length);
  //   return subjectMatterOptions[randomIndex];
  // };

  // const isLabelInOptions = (label: string) => {
  //   return subjectMatterOptions.some((optionArray) =>
  //     optionArray.some((option) => option.label === label)
  //   );
  // };

  // useEffect(() => {
  //   if (isLabelInOptions(subject[0].label)) {
  //     setSubjectMatter(getRandomSubjectMatter());
  //   }
  // }, []);

  useEffect(() => {
    if (currentUser && currentUser.email && currentUser.email) {
      service.getUserByEmail(currentUser.email).then((res: any) => {
        const account_type = res?.account_type;
        setIsAccountType(account_type);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchCaption = async () => {
      if (
        initialImageKey &&
        isAccountType !== "free" &&
        isAccountType !== null &&
        isAccountType !== ""
      ) {
        try {
          const res = await service.get_prompt_initialImage(initialImage);
          const caption = res.data.replace("Caption: ", "");
          if (subject.length > 0) {
            const updatedSubject = [...subject];
            updatedSubject[0] = { ...updatedSubject[0], label: caption };
            setSubjectMatter(updatedSubject);
          }
        } catch (error) {
          console.error("Error to get image caption", error);
        }
      }
    };
    fetchCaption();
  }, [isAccountType]);

  const newPromptLine = () => {
    if (subject[subject.length - 1].label !== "") {
      setSubjectMatter([
        ...subject,
        { label: "", time: "00:00", negativePrompt: "" },
      ]);
    }
  };

  const updateSubjectTime = () => {
    const auxSub = [];
    for (let i = 0; i < subject.length; i++) {
      const timeFormat = formatMinutes(
        start + +(delta / subject.length).toFixed(0) * i
      );
      const time = subject[i].time !== "00:00" ? subject[i].time : timeFormat;
      const element = subject[i];
      auxSub.push({ label: element.label, time: timeFormat, error: false });
    }
    setSubjectMatter([...auxSub]);
  };

  const setTypeTiming = (type: string) => {
    // updateSubjectTime();
    setTiming(type);
    if (type === "automatic") {
      setAutomaticTime(true);
    } else {
      setAutomaticTime(false);
    }
  };

  // ADD A LINE WHEN INPUT AN ENTER ON PROMPT
  const onSubmit = (e: any) => +e.preventDefault() + +newPromptLine();

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div className="p-4 overflow-auto">
      <div className="d-flex flex-column">
        {!isMobileDevice() && (
          <p style={{ textAlign: "center" }}>{DESCRIPTION}</p>
        )}
        {!isMobileDevice() && <p style={{ textAlign: "center" }}>{TIP}</p>}
        {isMobileDevice() && (
          <p style={{ textAlign: "center" }}>{SUBTITLE2_MOBILE}</p>
        )}
        <div className="d-flex justify-content-center">
          {showModalPurchase && (
            <ModalPurchase
              show={showModalPurchase}
              onHide={() => setShowModalPurchase(false)}
            />
          )}
          {showModalRemain && (
            <ModalRemainCredits
              show={showModalRemain}
              onHide={() => setShowModalRemain(false)}
            />
          )}
          {!isMobileDevice() && (
            <label style={{ margin: "6px" }}>{TIMING_LABEL}: </label>
          )}
          {isMobileDevice() && (
            <label style={{ margin: "6px", fontSize: "small" }}>
              {TIMING_LABEL}:{" "}
            </label>
          )}
          <div
            className="timing-container"
            style={{
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <button
              style={{ color: "#fff" }}
              className={
                timing === "manual" ? "timing-active" : "timing-inactive"
              }
              onClick={() => setTypeTiming("manual")}
            >
              Manual
            </button>
            <button
              style={{ color: "#fff" }}
              className={
                timing === "automatic" ? "timing-active" : "timing-inactive"
              }
              onClick={() => setTypeTiming("automatic")}
            >
              Automatic
            </button>
          </div>
        </div>
      </div>
      <form className="d-flex flex-column gap-3" {...{ onSubmit }}>
        {subject &&
          subject.map((...v: any) => (
            <Prompt
              key={v[1]}
              {...v[0]}
              index={v[1]}
              timing={timing}
              enableNegativePrompts={enableNegativePrompts}
              setNegativePrompts={setNegativePrompts}
              length={v[2]?.length}
            />
          ))}
        <div className="d-flex justify-content-center">
          <div className="prompt-add-btn" onClick={newPromptLine}>
            <i className="bi bi-plus" />
            {ADD}
          </div>
        </div>
      </form>
    </div>
  );
}

function StartingPrompt() {
  const { subjectMatter, setUserSubjectMatter } = useStartingFrame();
  const onChange = (e: any) => setUserSubjectMatter(e.target.value);
  const value = subjectMatter;

  return (
    <>
      <div className="border rounded prompt" style={{ marginBottom: "15px" }}>
        <input type="text" className="col-12" {...{ onChange, value }} />
      </div>
      <p style={{ textAlign: "center" }}>{TIP}</p>
    </>
  );
}

function Prompt(props: any) {
  const { index, length } = props;
  const { subject, setSubjectMatter } = useSubjectMatter();
  const { enableNegativePrompts, setNegativePrompts } = useNegativePrompts();
  const [showOptionalInputs, setShowOptionalInputs] = useState(false);
  const value = subject[index].label || "";
  const time = subject[index].time || "00:00";
  const negativePrompt = subject[index].negativePrompt || "";
  const error = subject[index].error || false;
  const { end } = useAudioTrim();

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const handleTimeChange = (e: any) => {
    const newTime = e.target.value;
    setSubjectMatter(
      subject.map((a: any, b: any) =>
        b === index ? { ...a, time: newTime } : a
      )
    );
  };

  const handleNegativePromptChange = (e: any) => {
    setSubjectMatter(
      subject.map((a: any, b: any) =>
        b === index ? { ...a, negativePrompt: e.target.value } : a
      )
    );
  };

  const oC1 = (e: any) => {
    //if (validValue(formatSeconds(e.target.value), index )){
    setSubjectMatter(
      subject.map((a: any, b: any) =>
        b === index ? { label: value, time: e.target.value } : a
      )
    );
    //}
  };

  const oC2 = (e: any) => {
    setSubjectMatter(
      subject.map((a: any, b: any) =>
        b === index ? { label: value, time, negativePrompt: e.target.value } : a
      )
    );
  };

  const toggleOptionalInputs = () => {
    setShowOptionalInputs(!showOptionalInputs);
    setNegativePrompts(showOptionalInputs);
  };

  const handleBlur = (e: any) => {
    const newTime = formatSeconds(e.target.value);
    if (!validTime(subject, newTime, index, end)) {
      let startTime = index === 0 ? "00:00" : subject[index - 1].time;
      let endTime =
        index === subject.length - 1
          ? formatMinutes(end)
          : subject[index + 1].time;
      toast.error(
        `The timing of the prompt "${subject[index].label}" must be between ${startTime} and ${endTime}`,
        { duration: 5000 }
      );
    }
    setSubjectMatter(
      subject.map((a: any, b: any) => {
        let error = false;
        if (!validTime(subject, formatSeconds(a.time), b, end)) {
          error = true;
        }
        return { ...a, error };
      })
    );
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "cameraAngle") {
      const currentLabel = subject[index].label;
      const currentValue = currentLabel.split(" of ")[1] || currentLabel;

      const newLabel = `${value} of ${currentValue}`;

      if (value !== currentValue) {
        setSubjectMatter(
          subject.map((a: any, b: any) =>
            b === index ? { ...a, label: newLabel } : a
          )
        );
      }
    }

    if (name === "subjectInput") {
      setSubjectMatter(
        subject.map((a: any, b: any) =>
          b === index ? { ...a, label: value } : a
        )
      );
    }
  };

  const onBlur = (e: any) => {
    if (!validTime(subject, formatSeconds(e.target.value), index, end)) {
      let startTime = index === 0 ? "00:00" : subject[index - 1].time;
      let endTime =
        index === subject.length - 1
          ? formatMinutes(end)
          : subject[index + 1].time;
      toast.error(
        `The timing of the prompt "${subject[index].label}" must be between ${startTime} and ${endTime}`,
        { duration: 5000 }
      );
    }
    setSubjectMatter(
      subject.map((a: any, b: any) => {
        let error = false;
        if (!validTime(subject, formatSeconds(a.time), b, end)) {
          error = true;
        }
        return { label: a.label, time: a.time, error };
      })
    );
  };

  const onChange = (e: any) => {
    setSubjectMatter(
      subject.map((a: any, b: any) =>
        b === index ? { label: e.target.value, time, negativePrompt } : a
      )
    );
  };

  const remove = () =>
    setSubjectMatter(subject.filter((...v: any) => v[1] !== index));

  useEffect(() => {
    const meta = document.createElement("meta");
    meta.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    );
    meta.setAttribute("name", "viewport");
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  return (
    <>
      {isMobileDevice() ? (
        <div>
          <div
            className="row col-12 align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <div style={{ width: "200px" }}>
              <select
                style={{
                  fontSize: "11px",
                  height: "35px",
                  width: "100%",
                  border: "None",
                }}
                name="cameraAngle"
                className="form-select rounded border"
                onChange={handleChange}
                value={subject[index].label.split(" of ")[0]}
              >
                <option value="Camera Angle">Camera Angle</option>
                <option value="Portrait">Portrait of</option>
                <option value="Close up">Close-up</option>
                <option value="Medium shot">Medium shot</option>
                <option value="Low angle">Low angle</option>
                <option value="Viewpoint">Point of View</option>
                <option value="From below">From below</option>
                <option value="From above">From above</option>
                <option value="Extreme closeup">Extreme closeup</option>
                <option value="Field's Depth">Depth of field</option>
                <option value="Aeriel view">Aerial view</option>
                <option value="Full body">Full body</option>
              </select>
            </div>

            {props.timing === "manual" && (
              <div
                style={{ width: "85px", marginRight: "10px" }}
                className={`prompt rounded ${error ? "time-error" : ""}`}
              >
                <i
                  className="bi bi-clock"
                  style={{
                    marginRight: "0.001rem",
                    marginLeft: "0.5rem",
                    width: "18px",
                  }}
                ></i>
                <input
                  type="text"
                  value={time}
                  onChange={handleTimeChange}
                  onBlur={handleBlur}
                  placeholder="MM:SS"
                  className="flex-grow-0"
                  style={{
                    width: "60px",
                    textAlign: "center",
                    border: "none",
                    backgroundColor: "#0000",
                    fontSize: window.innerWidth < 1400 ? "13px" : "13px",
                  }}
                />
              </div>
            )}
          </div>

          <div className="row">
            <div
              style={{
                width: "100%",
                marginLeft:
                  props.timing === "manual" && window.innerWidth > 1600
                    ? "10px"
                    : undefined,
              }}
              className={`rounded prompt ${
                props.timing === "manual" && window.innerWidth < 1600
                  ? "col-12"
                  : props.timing === "manual"
                  ? "col-12"
                  : props.timing === "automatic"
                  ? "col-12"
                  : ""
              }`}
            >
              <input
                className="col-12"
                type="text"
                name="subjectInput"
                onChange={handleChange}
                placeholder="you must give a positive prompt to describe what you want see"
                value={value}
                style={{
                  border: "none",
                  backgroundColor: "#0000",
                  fontSize: "11px",
                }}
              />
              {index !== 0 && <RemoveBtn callback={remove} />}
              <button style={{ display: "none" }} />
            </div>
            <div>
              <button
                type="button"
                className="btn"
                onClick={toggleOptionalInputs}
                style={{ fontWeight: "lighter", fontSize: "0.8em" }}
              >
                Negative prompt (optional){" "}
                {showOptionalInputs ? (
                  <i className="bi bi-chevron-down"></i>
                ) : (
                  <i className="bi bi-chevron-right"></i>
                )}
              </button>
              {showOptionalInputs && (
                <div>
                  <input
                    type="text"
                    onChange={handleNegativePromptChange}
                    value={negativePrompt || ""}
                    className={`${
                      window.innerWidth < 1400 ? "col-12" : "col-10"
                    } rounded prompt`}
                    placeholder="you can give a negative prompt to describe what you don't want see. Common examples are: hands, nudity"
                    style={{
                      backgroundColor: "#0000",
                      fontSize: window.innerWidth < 1400 ? "0.58em" : "0.7em",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-2" style={{ width: "150px" }}>
            <select
              style={{ fontSize: "11px" }}
              name="cameraAngle"
              className="form-select"
              onChange={handleChange}
              value={subject[index].label.split(" of ")[0]}
            >
              <option value="Camera Angle">Camera Angle</option>
              <option value="Portrait">Portrait of</option>
              <option value="Close up">Close-up</option>
              <option value="Medium shot">Medium shot</option>
              <option value="Low angle">Low angle</option>
              <option value="Viewpoint">Point of View</option>
              <option value="From below">From below</option>
              <option value="From above">From above</option>
              <option value="Extreme closeup">Extreme closeup</option>
              <option value="Field's Depth">Depth of field</option>
              <option value="Aeriel view">Aerial view</option>
              <option value="Full body">Full body</option>
            </select>
          </div>

          {props.timing === "manual" && (
            <div
              style={{ width: "85px", marginRight: "10px" }}
              className={`rounded prompt ${error ? "time-error" : ""} col-1`}
            >
              <i
                className="bi bi-clock"
                style={{
                  marginRight: "0.001rem",
                  marginLeft: "0.5rem",
                  width: "18px",
                }}
              ></i>
              <input
                type="text"
                value={time}
                onChange={handleTimeChange}
                onBlur={handleBlur}
                placeholder="MM:SS"
                className="flex-grow-0"
                style={{
                  width: "60px",
                  textAlign: "center",
                  border: "none",
                  backgroundColor: "#0000",
                  fontSize: window.innerWidth < 1400 ? "13px" : "13px",
                }}
              />
            </div>
          )}
          <div
            style={{
              width:
                props.timing === "manual" && window.innerWidth > 1600
                  ? "500px"
                  : undefined,
              marginLeft:
                props.timing === "manual" && window.innerWidth > 1600
                  ? "10px"
                  : undefined,
            }}
            className={`rounded prompt ${
              props.timing === "manual" && window.innerWidth < 1600
                ? "col-7"
                : props.timing === "manual"
                ? "col-9"
                : props.timing === "automatic"
                ? "col-9"
                : ""
            }`}
          >
            <input
              className="col-12"
              type="text"
              name="subjectInput"
              onChange={handleChange}
              value={value}
              placeholder="you must give a positive prompt to describe what you want see"
              style={{ border: "none", backgroundColor: "#0000" }}
            />
            {index !== 0 && <RemoveBtn callback={remove} />}
            <button style={{ display: "none" }} />
          </div>
          <div>
            <button
              type="button"
              className="btn"
              onClick={toggleOptionalInputs}
              style={{ fontWeight: "lighter", fontSize: "0.8em" }}
            >
              Negative prompt (optional){" "}
              {showOptionalInputs ? (
                <i className="bi bi-chevron-down"></i>
              ) : (
                <i className="bi bi-chevron-right"></i>
              )}
            </button>
            {showOptionalInputs && (
              <div>
                <input
                  type="text"
                  onChange={handleNegativePromptChange}
                  value={negativePrompt || ""}
                  className={`${
                    window.innerWidth < 1400 ? "col-12" : "col-10"
                  } rounded prompt`}
                  placeholder="you can give a negative prompt to describe what you don't want see. Common examples are: hands, nudity"
                  style={{
                    backgroundColor: "#0000",
                    fontSize: window.innerWidth < 1400 ? "0.58em" : "0.7em",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const RemoveBtn = ({ callback }: { callback: Function }) => (
  <i onClick={() => callback()} className="bi bi-trash prompt-del-btn" />
);

const { REACT_APP_S3_BUCKET_RESTYLE_NAME } = process.env;

class ImageToImageSettings {
  base_model: string = "dreamshaper_631BakedVae";
  use_ipadapter: string = "True";
  ipadapter_model: string = "ip-adapter-plus-face_sd15";
  ipadapter_scale: number = 0.7;
  ipadapter_reference_image: string = "ip_image_9.png";
  number_of_controlnets: number = 3;
  controlnets_models: string[] = ["mediapipe_face", "softedge", "lineart"];
  controlnets_preprocesses: string[] = ["face", "hed", "lineart"];
  subject_matter: string = "a woman playing a violin";
  prompt_positive_style: string =
    "realism, vivid colors, gouache, highly detailed, soft shadows, by Rene Magritte";
  prompt_negative: string =
    "nude, naked, Watermark, Text, censored, deformed, bad anatomy, disfigured, poorly drawn face, mutated, extra limb, ugly, poorly drawn hands, missing limb, floating limbs, disconnected limbs, disconnected head, malformed hands, long neck, mutated hands and fingers, bad hands, missing fingers, cropped, worst quality, low quality, mutation, poorly drawn, huge calf, bad hands, fused hand, missing hand, disappearing arms, disappearing thigh, disappearing calf, disappearing legs, missing fingers, fused fingers, abnormal eye proportion, Abnormal hands, abnormal legs, abnormal feet, abnormal fingers";
  seed: number = 3574867805;
  steps: number = 30;
  guidance_scale: number = 10.0;
  denoising_strength: number = 0.7;
  sampler: string = "Euler a";
  controlnets_scales: number[] = [0.9, 0.8, 0.8];
  tile_res: number = 768;
}

class StagesSettings {
  video_path: string = "";
  video_name: string = "";
  masking_method: string = "transparent-background";
  frame_width: number = 640;
  frame_height: number = 640;
  key_min_gap: number = 13;
  key_max_gap: number = 15;
  key_th: number = 10.0;
  mask_mode: string = "Normal";
  output_type: string = "entire";
  scaling_factor: number = 1.5;
}

class Stage {
  stages_settings: StagesSettings = new StagesSettings();
  image_to_image_settings: ImageToImageSettings = new ImageToImageSettings();
}

export class RestyleRequest {
  owner_id: string = "";
  video_name: string = "";
  single_stage_settings: { stage_normal: Stage } = {
    stage_normal: new Stage(),
  };
  two_stage_settings: any = {};

  constructor(
    ownerId: string,
    videoName: string,
    positivePrompt: string = "Synthwave, vibrant colors, neon lights, grid patterns, nostalgic, in the style of Retrowave, 8K",
    subjectMatter: string,
    outputType: string,
    frameWidth: number,
    frameHeight: number,
    scalingFactor: number = 1.5,
    ipadapterReferenceimage: string = "ip_image_9.png"
  ) {
    this.owner_id = ownerId;
    this.video_name = videoName;

    const bucket = process.env.REACT_APP_S3_BUCKET_RESTYLE_NAME;

    this.single_stage_settings.stage_normal.stages_settings.video_name =
      videoName;
    this.single_stage_settings.stage_normal.stages_settings.video_path = `${bucket}${encodeURIComponent(
      ownerId
    )}/original-videos/${encodeURIComponent(videoName)}`;
    this.single_stage_settings.stage_normal.stages_settings.output_type =
      outputType; // Set output_type
    this.single_stage_settings.stage_normal.stages_settings.frame_width =
      frameWidth; // Set frame_width
    this.single_stage_settings.stage_normal.stages_settings.frame_height =
      frameHeight; // Set frame_height
    this.single_stage_settings.stage_normal.image_to_image_settings.prompt_positive_style =
      positivePrompt;
    this.single_stage_settings.stage_normal.image_to_image_settings.subject_matter =
      subjectMatter;
    this.single_stage_settings.stage_normal.stages_settings.scaling_factor =
      scalingFactor;
    this.single_stage_settings.stage_normal.image_to_image_settings.ipadapter_reference_image =
      ipadapterReferenceimage;
  }

  toJSON() {
    return {
      owner_id: this.owner_id,
      video_name: this.video_name,
      single_stage_settings: {
        stage_normal: this.single_stage_settings.stage_normal,
      },
      two_stage_settings: this.two_stage_settings,
    };
  }
}
